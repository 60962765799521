import {useGesture, useDrag, useMove} from '@use-gesture/react';
import {useSpring, config} from '@react-spring/three';
import {useCallback, useEffect, useState} from "react";
import { useThree } from "@react-three/fiber";

const Gestures = (bounds, props) => {
    const { isCardSelected, showNewCardBox } = props
    const {gl} = useThree()

    const [{x, y}, api] = useSpring(() => ({x: 0, y: 0, config: config.slow}));

    const fn = useCallback(
        ({offset: [dx, dy]}) => {
            api.start({x: !isCardSelected && !showNewCardBox ? -dx / 100 : x, y: !isCardSelected && !showNewCardBox ? dy / 100 : y})
        }, [bounds, x, y, api]
    )

    const bind2 = useDrag(({ down, offset: [ox, oy] }) => api.start({ x: ox, y: oy, immediate: down }), {
        bounds: { left: -100, right: 100, top: -50, bottom: 50 }
    })

    const moveFn = useCallback(
        (state) => {
            const xPos = window.innerWidth / 2 - state.values[0]
            const yPos = window.innerHeight / 2 - state.values[1]
            const xMult = Math.abs(xPos) / (window.innerWidth / 2) * 0.01
            const yMult = Math.abs(yPos) / (window.innerHeight / 2) * 0.01

            api.start({x: !isCardSelected || !showNewCardBox ? -xPos * xMult : x, y: !isCardSelected || !showNewCardBox ? yPos * yMult : y})
        }, [bounds, x, y, api]
    )

    // const fn =
    //     ({offset: [dx, dy]}) => {
    //         console.log('dragging:', dx)
    //         set({x: -dx / 100, y: dy / 100})
    //     }


    const bind = useDrag(fn, {target: gl.domElement, bounds: { left: -500, right: 300, top: -200, bottom: 200 }})
    // const bindMove = useMove(moveFn, {target: window})
    // const bind = useGesture({onDrag: fn, onMove: fn})

    useEffect(() => {
        props && props.target && bind()
    }, [props, bind])

    return [x, y, bind]
}

export default Gestures