import React, {useEffect} from "react";
import Card from "./Card";
import {useBounds} from '@react-three/drei'
import {prompts} from "../prompts";

const CardGrid = (props) => {
    const {onCardClicked, isCardSelected, day, showNewCardBox, mobile} = props

    const createSpiralGrid = (n) => {
        let x = 0
        let y = 0
        let delta = [0, -1]
        let width = n
        let height = n

        let result = []
        for (let i = Math.pow(Math.max(width, height), 2); i > 0; i--) {
            if ((-width / 2 < x && x <= width / 2)
                && (-height / 2 < y && y <= height / 2)) {
                result.push({x,y})
            }

            if (x === y
                || (x < 0 && x === -y)
                || (x > 0 && x === 1 - y)) {
                // change direction
                delta = [-delta[1], delta[0]]
            }

            x += delta[0];
            y += delta[1];
        }

        return result
    }

    const renderCards = () => {
        let grid = []
        const coordinates = createSpiralGrid(12)

        const promptsReleased = prompts.slice(0, day)

        coordinates.map((coord, i) => {
            grid.push(
                <Card
                    onCardClicked={onCardClicked}
                    isCardSelected={isCardSelected}
                    key={i}
                    position={[coord.x * 1.25, coord.y * 1.75, 0.1]}
                    promptIndex={i}
                    prompts={promptsReleased}
                    index={i}
                    showNewCardBox={showNewCardBox}
                    mobile={mobile}
                />)
        })

        return grid
    }

    return (
        <group rotation={[-55 * Math.PI / 180, 0, -45 * Math.PI / 180]}>
            <directionalLight position={[10, 0, 15]} castShadow shadow-mapSize={mobile ? 2048 : 2048}>
                <orthographicCamera attach="shadow-camera" args={[-10, 10, -10, 10, 0.1, 50]}/>
            </directionalLight>
            <mesh receiveShadow position-z={-0.0025}>
                <circleGeometry args={[13]}/>
                <meshStandardMaterial color="#e39738"/>
            </mesh>
            {renderCards()}
        </group>
    )
}

export default CardGrid
