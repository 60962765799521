import {useEffect, useState} from "react";
import AppCanvas from "./Components/AppCanvas";
import {Box, Text} from "@chakra-ui/react";
import { useAuthStore } from "./authStore"

const App = () => {

    const authState = useAuthStore((state) => state.authState)
    // const setAuthState = useAuthStore((state) => state.setAuthState)

    return (
        <Box>
            {authState ? <AppCanvas/> : <Text>No auth</Text>}
        </Box>
    );
}

export default App;
