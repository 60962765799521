import React, {useState, useRef, useEffect} from 'react';
import {Bounds, SoftShadows, useBounds, MapControls} from '@react-three/drei'
import {useThree} from "@react-three/fiber";
import CardGrid from "./CardGrid";
import Camera from "./Camera";
import isMobile from "is-mobile";

const START_DAY = 120

const AppScene = () => {
    const [isCardSelected, setIsCardSelected] = useState(false)
    const [focusCardVisible, setFocusCardVisible] = useState(false)
    const [showNewCardBox, setShowNewCardBox] = useState(true)
    const [promptIndex, setPromptIndex] = useState(-1)
    const [day, setDay] = useState()
    const [mobile, setMobile] = useState(false)
    const [margin, setMargin] = useState(0.125)

    const {camera} = useThree()

    // Get and calculate the current day we're running on
    useEffect(() => {
        camera.fov = 10

        const fetchDate = async () => {
            const res = await fetch("https://worldtimeapi.org/api/timezone/America/Los_Angeles")
            const date = JSON.parse(await res.text())
            console.log(date.day_of_year)
            const dayCount = date.day_of_year - START_DAY > 0 ? date.day_of_year - START_DAY : 1

            console.log("Day count:", dayCount)

            // setDay(dayCount)
            setDay(100)
        }

        const searchParams = new URLSearchParams(document.location.search)
        const dayParam = searchParams.get('day')

        console.log(dayParam)

        if (dayParam) {
            setDay(dayParam)
        } else {
            fetchDate()
        }
    }, [])

    // disable new card box after day 33
    useEffect(() => {
        if (day > 33) {
            setShowNewCardBox(false)
        }
    }, [day])

    // Check if mobile
    useEffect(() => {
        setMobile(isMobile())
        console.log("Is mobile:", isMobile())
    }, [])

    // calculate margin based on screen
    useEffect(() => {
        const w = window.innerWidth
        if (w <= 1000) {
            setMargin(0.07)
        }

        console.log('setting margin to:', margin)
    }, [])

    const onCardClicked = (promptIndex) => {
        console.log('Card clicked')
        setPromptIndex(promptIndex)
        setIsCardSelected(true)
    }

    const onDismissCard = () => {
        setIsCardSelected(false)
    }

    // console.log('main scene re-rendering')
    return (
        day ?
            <group>
                <ambientLight intensity={.25}/>
                <SoftShadows size={mobile ? 125 : 125} samples={mobile ? 16 : 32} enabled/>
                <Bounds fit clip observe margin={margin}>
                    <CardGrid onCardClicked={onCardClicked} isCardSelected={isCardSelected} day={day}
                              showNewCardBox={showNewCardBox} mobile={mobile} />
                </Bounds>

                <Camera position={[0, 0, 50]} zoom={1} fov={10}
                        isCardSelected={isCardSelected}
                        onDismissCard={onDismissCard}
                        promptIndex={promptIndex}
                        showNewCardBox={showNewCardBox}
                        newCardPromptIndex={day}
                        setIsCardSelected={setIsCardSelected}
                        setFocusCardVisible={setFocusCardVisible}
                        focusCardVisible={focusCardVisible}
                        setShowNewCardBox={setShowNewCardBox}
                        mobile={mobile}
                />
            </group>
            :
            <group/>
    );
};

export default AppScene;
