import {useState, useRef, useEffect} from "react";
import {useFrame} from "@react-three/fiber";
import {useTexture} from "@react-three/drei";
import {useSpring, animated, config} from '@react-spring/three';
// import {prompts} from "../prompts";

const Card = ({position, onCardClicked, isCardSelected, promptIndex, prompts, index, showNewCardBox, mobile}) => {

    const [isActive, setIsActive] = useState()
    const [hover, setHover] = useState(false)
    const [hovering, setHovering] = useState(false)
    const [elapsed, setElapsed] = useState(0)

    const pulseRef = useRef()
    const cardRef = useRef()

    // const texture = useTexture(prompts[promptIndex] ? 'card' + Number(promptIndex % 4 + 1) + '.png' : 'empty_card.png')
    const texture = useTexture('card' + Number(index % 6 + 1) + '.png')
    const alphaTexture = useTexture('empty_card_mask.png')

    useEffect(() => {
        if (prompts[promptIndex]) {
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }, [prompts])

    const hoverTransition = useSpring({
        to: {z: hover && !isCardSelected && isActive && !showNewCardBox ? 0.275 : 0.1},
        config: config.default,
        onRest: () => {
            hover ? setHovering(true) : setHovering(false)
        }
    })

    const onCardSelected = () => {
        if (mobile) {
            if (!isCardSelected && isActive && !showNewCardBox) {
                console.log('click on mobile')
                onCardClicked(promptIndex)
            }
        } else {
            console.log('click on desktop')
            if (!isCardSelected && hovering && isActive && !showNewCardBox) onCardClicked(promptIndex)
        }
    }

    useFrame(({clock}) => {
        if (hover && !isCardSelected && isActive) {
            setElapsed(elapsed + clock.getDelta() * 250)
            const zOffset = Math.sin(elapsed) * 0.02
            pulseRef.current.position.z = zOffset
        } else {
            setElapsed(0)
            pulseRef.current.position.z = 0
        }
    })

    const renderMaterial = () => {
        if (isActive) {
            return <meshPhongMaterial transparent={false} color="white" map={texture}/>
        } else {
            return <meshStandardMaterial transparent={true} color="#9c9c9c" map={texture} alphaMap={alphaTexture}/>
        }
    }

    return (
        <group ref={pulseRef}>
            <animated.mesh
                ref={cardRef}
                castShadow
                position={position}
                position-z={hoverTransition.z}
                onPointerEnter={!mobile ? () => setHover(true) : null}
                onPointerLeave={!mobile ? () => setHover(false) : null}
                onClick={onCardSelected}
                // onPointerDown={mobile ? onCardSelected : null}
            >
                <planeGeometry args={[1, 1.5]}/>
                {renderMaterial()}
            </animated.mesh>
        </group>
    )
}

export default Card