import {Box} from '@chakra-ui/react'
import {useEffect, useState} from 'react'
import { useLoaderData, Navigate } from "react-router-dom"
import { useAuthStore } from "../authStore"

export const loader = ({params}) => {
    const authId = params.authId
    return { authId }
}

const Auth = () => {

    const {authId} = useLoaderData()

    const authState = useAuthStore((state) => state.authState)
    const setAuthState = useAuthStore((state) => state.setAuthState)

    const [isAuth, setIsAuth] = useState(false)

    useEffect(() => {
        if (authId === "7d5737db-ea00-4476-9048-19aff081ecc1") {
            setAuthState(true)
            setIsAuth(true)
        } else {
            console.log("Not authenticated")
        }
    }, [])

    const searchParams = new URLSearchParams(document.location.search)
    const dayParam = searchParams.get('day')
    let url
    if (dayParam) {
        url = "/?day=" + dayParam
    } else {
        url = "/"
    }

    return (
        isAuth ? <Navigate to={url} replace={true} /> : <Box>No auth</Box>
    )
}

export default Auth;
