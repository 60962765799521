import {Box} from '@chakra-ui/react'
import React, {useState} from 'react';
import {Canvas} from '@react-three/fiber';
import AppScene from './AppScene'

const AppCanvas = () => {
    return (
        <Box w='100vw' h='100vh' position='relative'>
            <Canvas shadows gl={{ preserveDrawingBuffer: true }} >
                <AppScene/>
            </Canvas>
        </Box>
    );
};

export default AppCanvas;
