import React, {useEffect, useRef} from "react";
import {useThree} from "@react-three/fiber";
import Gestures from "./Gestures";
import {animated} from "@react-spring/three";
import FocusCard from "./FocusCard";
import BgBlur from "./BgBlur";
import {OrthographicCamera, PerspectiveCamera, Hud} from "@react-three/drei";
import NewCardBox from "./NewCardBox";

const Camera = (props) => {
    const {
        isCardSelected,
        onDismissCard,
        promptIndex,
        showNewCardBox,
        setIsCardSelected,
        focusCardVisible,
        setFocusCardVisible,
        setShowNewCardBox,
        newCardPromptIndex,
        mobile
    } = props

    const cameraRef = useRef()
    const {set} = useThree()

    const [x, y] = Gestures([-100, 100], props)

    useEffect(() => {
        set({camera: cameraRef.current})
    }, [])

    useEffect(() => {
        cameraRef.current.updateProjectionMatrix();
    }, [])


    return (
        <group>
            <animated.group position-x={x.to((x) => (x))}
                            position-y={y.to((y) => (y))}>
                <group rotation={[0 * Math.PI / 180, 0 * Math.PI / 180, 0 * Math.PI / 180]}>
                    <OrthographicCamera ref={cameraRef} {...props}/>
                    <group>
                        <Hud>
                            <PerspectiveCamera makeDefault position={[0, 0, 9]}/>
                            <BgBlur shouldEnter={isCardSelected || showNewCardBox}/>
                            {showNewCardBox && !focusCardVisible ? <NewCardBox setIsCardSelected={setIsCardSelected}
                                                                               setShowNewCardBox={setShowNewCardBox} mobile={mobile}/> : null}
                            <FocusCard
                                shouldEnter={isCardSelected}
                                dismissCard={onDismissCard}
                                promptIndex={promptIndex}
                                newCardPromptIndex={newCardPromptIndex}
                                setFocusCardVisible={setFocusCardVisible}
                                setShowNewCardBox={setShowNewCardBox}
                                newCardPromptIndex={newCardPromptIndex}
                                mobile={mobile}
                            />
                        </Hud>
                    </group>
                </group>
            </animated.group>
        </group>
    )
}

export default Camera