import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Auth, {loader as authLoader} from "./Components/Auth";
import {ChakraProvider} from '@chakra-ui/react'
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

const theme = {
    styles: {
        global: {
            'body': {
                touchAction: 'none',
                position: 'fixed',
                overflow: 'hidden',
            }
        },
    },
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path: "auth/:authId",
        element: <Auth/>,
        loader: authLoader
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            {/*<App/>*/}
            <RouterProvider router={router} />
        </ChakraProvider>
    </React.StrictMode>
);
