import {useState, useRef, useEffect} from "react";
import {useFrame, useThree} from "@react-three/fiber";
import {useSpring, animated, config} from '@react-spring/three';
import {MeshTransmissionMaterial} from "@react-three/drei";

const BgBlur = ({shouldEnter}) => {

    const bgBlurRef = useRef()

    useEffect(() => {
        if (bgBlurRef.current) {
            // bgBlurRef.current.renderOrder = 9998
            // bgBlurRef.current.material.depthTest = false
        }
    }, [])


    const blurTransition = useSpring({
        to: {blur: shouldEnter ? 0.55 : 0},
        config: config.gentle
    })

    const {color} = useSpring({
        to: {color: shouldEnter ? 'DimGray' : 'White'},
        config: config.gentle
    })

    const AnimatedMeshTransmissionMaterial = animated(MeshTransmissionMaterial)

    return (
        <group position={[0, 0, 4]}>
            <animated.mesh ref={bgBlurRef}>
                <planeGeometry args={[100, 100]} />
                <animated.meshStandardMaterial transparent color='black' opacity={blurTransition.blur}/>
            </animated.mesh>
        </group>
    )
}

export default BgBlur