export const prompts = [
    "Take a deep breath and with your inhale scrunch up your shoulders all the way to your ears, " +
    "hold the tension there along with your breath for 3 whole counts... increasing the tightness as " +
    "much as you can... with a deep long exhale, release your shoulders and let out a " +
    "loud sigh. " +
    "\n\n" +
    "Take a moment to notice how good it feels to relax your body... Now see if you can keep that " +
    "good feeling going throughout the day!",

    "Mindfully Worry: Take a look at your day.. schedule in 5 minutes to worry... to worry about " +
    "anything and everything that is bothering you or making you feel anxious. The trick here is that " +
    "outside of those scheduled 5 minutes of mindfully worrying, no other worry time is allowed! " +
    "\n\n" +
    "Optimism Tip: This takes practice… if you aren't able to do just one 5 minute worry session a " +
    "day, start with two or three scheduled, 5 minute worry sessions and slowly work your way " +
    "towards less!",

    "Align With Your Values: Remind yourself of your values often. Take a moment, with total free " +
    "thought to list off 3 of your core values. What are 3 things that are most important to you right " +
    "now? Now take these 3 values with you into your day.",

    "Ask yourself right now, How do I want to feel? Just having an intention of how you want to " +
    "feel is good enough. That's all. If you are feeling like some extra credit: drop the \"want\" and " +
    "imagine what it would be like to live that feeling, simply say, \"I feel.\"",

    "Did you know that a smile spurs a powerful chemical reaction in the brain that triggers " +
    "happiness? Smile, right NOW! Smile as big as you can... and then laugh at how silly you might " +
    "be feeling... and then laugh about how happy that makes you feel!" +
    "\n\n" +
    "Okay, now you have just boosted happiness, decreased stress hormones and worked on " +
    "increasing your immune system all this with just your beautiful smile and crack up laughter!",

    "Develop A Morning Ritual: What is the first thing you do when you wake up? Does it make " +
    "you feel good, motivated, and centered? If so, keep it up. If not, it's time to have some fun and " +
    "create a new first of the day routine!",

    "It doesn't really matter what time it is, or where you are... put on some music, get up and " +
    "dance! Dance like no one is watching, or like everyone is watching allow yourself 30 whole " +
    "seconds of complete freedom to find yourself in the beat.",

    "Seek out a piece of positive, happy news right now! So many unbelievably horrible things are " +
    "happening around us and we are aware of most of them. It is fine to be aware of these things, " +
    "however, so many incredible things are also happening around the world and we are aware of " +
    "so few of them." +
    "\n\n" +
    "Try googling something amazing happening! I mean, did you know that squirrels accidentally " +
    "plant hundreds of trees each year, simply because they forget where they put their " +
    "acorns? Or, that the tiger population in Nepal has just doubled in size? Or, that the Dalai Lama " +
    "has launched a Government-school program named \"The Happiness Curriculum\" in Delhi " +
    "serving over 800,000 students?!",

    "Take a moment to reflect and remember how badly you wanted something that you currently " +
    "have today. Whatever comes up, it could be something really big, or something really small... " +
    "reflect how badly you wished for it, and take a moment to appreciate all the effort you made " +
    "to manifest and realize it. YOU did it!",

    "Give yourself a dose of reality: Even the happiest life has ups and downs - it's just the way " +
    "you navigate through them that is of your concern and in your control." +
    "\n\n" +
    "Think about something that has been a struggle and think of something that has made you so " +
    "happy..." +
    "\n\n" +
    "A happy life is multidimensional and includes your joys and struggles… your life is a happy " +
    "life...",

    "Create space for something joyful that you are looking forward to doing, and get it on your " +
    "calendar now! This can be small or big, something that takes 5 minutes or an entire day...",

    "Embrace Vulnerability: Go ahead, name one thing out loud that makes you feel vulnerable " +
    "or exposed. Now place your hand on your heart and think about how strong you are to have " +
    "been able to mindfully uncover this beautiful self truth!",

    "What makes you happy? Without too much thought, judgment, or even intention, name 3 " +
    "things that make you happy right now!",

    "Meditate for just 3 minutes. Set a timer, get comfortable, close your eyes, and focus on your " +
    "breath. See if you can try and shift any thoughts that come up towards the quality " +
    "of your breath. Noticing how your chest rises and falls… and noticing what the air coming in " +
    "through your nose and out through your mouth feels likes... keep going, before your know it, 3 " +
    "minutes are up!" +
    "\n\n" +
    "Remember, life can always get hectic, but, almost everyone has just 3 minutes!",

    "Grab something you love to eat, it could be anything... Try and shift your focus away from " +
    "any self-judgment, or associations the food might start to conjure up. Focus on how thankful you " +
    "are to experience your senses... what does it taste like... smell like... feel like. sound like….. look " +
    "like?",

    "Give a positive shout out to someone right now. Call, text, email, write a letter, or express " +
    "in person, your appreciation or gratitude towards someone you are thankful for!" +
    "\n\n" +
    "Silent gratitude is great, but loud gratitude is even better!",

    "Try and do something kind for someone else today-- hold the door open for the next person " +
    "coming up behind you at the coffee shop, let someone in that's trying to change lanes, smile at " +
    "a passerby, bring a cup of tea for a colleague, anything that feels sincere to you...",

    "Reset Your Thoughts: Take a moment to stop everything that you are doing... look around " +
    "you and list 10 things you see. Without telling a story, or placing any judgment, simply name " +
    "each item, one by one..." +
    "\n\n" +
    "Yes, it's just like that \"I Spy” game you may have played as a child. Go on, try it out, it's fun! This " +
    "is a natural way to reset your thoughts, disrupt anxiety, and is a really easy way to get into a " +
    "mindful head space." +
    "\n\n" +
    "For example, \"I see a wood table, an orange tree, a fork, a picture frame\"... just remember, try to " +
    "stick to what you see, without any form of judgment!",

    "Change is a challenge for all of us. Name a change you withstood and came out of for the " +
    "better.. it can be any type of change at all. A job change... a move... a relationship " +
    "that was not serving you well.." +
    "\n\n" +
    "Wow, good job! You have successfully changed... and therefore, been through one of the most " +
    "difficult experiences of the human existence, now you can do anything!",

    "Think about a time you felt rejected or didn't quite get what you had hoped for... now humor " +
    "yourself, see if you can come up with 3 ways you grew from it.",

    "Just for today, instead of making a to-do list, make a ta-da list, or better yet, an \"DID IT\" list! " +
    "Jot down things you already did, it's amazing how accomplished you already are and the day " +
    "isn't even over!",

    "Remind yourself that nothing is permanent. Where you are in this exact moment, is not " +
    "where you will be forever. How does that make you feel?",

    "Watch Your Language: How are you telling your story? Try and see if you can clean it up, flip " +
    "it, switch it and uplift it! Our language is key. So often, how we describe our lives and days can " +
    "be in the form of negative or disempowered language." +
    "\n\n" +
    "Words like, \"busy\", \"chaotic\", \"overwhelmed', \"boring\", \"should\", \"have-to\", etc... are all words " +
    "that have a disempowering impact." +
    "\n\n" +
    "Try and tell a mini story about your day only using empowering, positively charged language, " +
    "this can be super fun... Try It!\n" +
    "Optimism Tip: Trade in \"I want\" for \"I am\" and \"I should\" for \"I want to\"!",

    "Check your expectations: Are you expecting something positive or negative to happen? Now " +
    "try and focus on the things you are positively expecting to occur!" +
    "\n\n" +
    "Optimism Tip: We often get what we Expect, NOT what we Want.",

    "As empathic creatures, our emotions are so often swayed by someone else's experience. " +
    "Try and commit to spending the entire day strong in your own emotional state, even while " +
    "around so many others." +
    "\n\n" +
    "You know what it's like... you can be having a great day, then you walk into an elevator at work " +
    "and you are listening to someone complain about the traffic, the bad coffee, the overwhelming " +
    "amount of \"have to's\" on their to-do list... then you catch yourself joining in, just merely trying to " +
    "relate... now you are having a bad day and noticing all the things that have the ability to bother " +
    "you..." +
    "\n\n" +
    "Try doing the exact opposite. Listen, witness, have empathy... BUT, stay true to your own " +
    "experience!",

    "Cultivate A Sense Of Humor: The ability to laugh at yourself or something you found funny " +
    "today. If you aren't already laughing out loud, try it!",

    "Be Yourself. Whatever and whoever that means… There are many people in this world, but " +
    "just one of YOU- how cool is that? Now take a moment to think about just one thing that makes " +
    "you, truly YOU.",

    "Just for a moment, see if you can challenge yourself to focus on the solutions, not the " +
    "problems. Whatever that means to you, whatever comes up... Just go with it!",

    "Embrace the magic of the number 3. Connect to your inner self… breathe deeply in for 3 " +
    "counts… hold for 3 counts... and exhale for 3 counts..." +
    "\n\n" +
    "Repeat... 3 times!",

    "Name one thing you like about yourself. It could be something that seems so small or " +
    "something that feels much bigger... it could be anything you truly, sincerely, like about yourself. " +
    "Your smile? Your loyalty? The way you tell a story? Your work ethic? Your sense of humor? Your " +
    "persistence... anything that is true!",

    "Celebrate your small wins just as you would celebrate the big ones. Did you get out of bed " +
    "today? WIN! Did you nourish yourself with any type of food or drink? WIN! Did you complete a " +
    "task you set out to do today? WIN! How have you been WINNING today?" +
    "\n\n" +
    "Think about it!",

    "It is much easier for us to point out the areas in which people around us need to improve " +
    "on... take a moment right now, to tell someone what they are really good at. Let someone know " +
    "that they are doing a great job, and mean it!",

    "Name just one thing that makes you proud of yourself… anything that comes to mind. Even " +
    "if it does not seem like it, you are accomplishing so much every single day and truly have so " +
    "many things to choose from... just pick one!"
]