import {useState, useRef, useEffect} from "react";
import {useFrame, useThree } from "@react-three/fiber";
import {useGLTF, useAnimations} from "@react-three/drei";
import {useSpring, animated, config} from '@react-spring/three';
import { LoopOnce } from "three";

const NewCardBox = ({setIsCardSelected, mobile}) => {

    const [shouldFlip, setShouldFlip] = useState(false)
    const [startAnim, setStartAnim] = useState(false)
    const [flipped, setFlipped] = useState(false)
    const [elapsed, setElapsed] = useState(0)

    const pulseRef = useRef()

    const {scene, animations} = useGLTF('card_box_anim.glb')
    const { mixer, clips, actions } = useAnimations(animations, scene)
    // console.log(actions)

    const flipTransition = useSpring({
        to: {rot: shouldFlip ?? !flipped ? 360 * Math.PI / 180 : 0},
        config: config.slow,
        onRest: () => {
            // setIsCardSelected(true)
        }
    })

    useEffect(() => {
        if (startAnim) {
            actions["Extrude_1|CINEMA_4D_Main|Layer0"].clampWhenFinished = true
            actions["Extrude_2|CINEMA_4D_Main|Layer0"].clampWhenFinished = true
            actions["Extrude_3|CINEMA_4D_Main|Layer0"].clampWhenFinished = true
            actions["fold2|CINEMA_4D_Main|Layer0"].clampWhenFinished = true

            actions["Extrude_1|CINEMA_4D_Main|Layer0"].setLoop(LoopOnce)
            actions["Extrude_2|CINEMA_4D_Main|Layer0"].setLoop(LoopOnce)
            actions["Extrude_3|CINEMA_4D_Main|Layer0"].setLoop(LoopOnce)
            actions["fold2|CINEMA_4D_Main|Layer0"].setLoop(LoopOnce)

            actions["Extrude_1|CINEMA_4D_Main|Layer0"].play()
            actions["Extrude_2|CINEMA_4D_Main|Layer0"].play()
            actions["Extrude_3|CINEMA_4D_Main|Layer0"].play()
            actions["fold2|CINEMA_4D_Main|Layer0"].play()

            // mixer.addEventListener('finished', (e) => {
            //     console.log(e, 'finished')
            //     setIsCardSelected(true)
            // });
            setTimeout(() => {
                setIsCardSelected(true)
            }, 2500)
        }
    }, [startAnim])

    const onBoxClicked = () => {
        setStartAnim(true)
        setShouldFlip(true)
    }

    useFrame(({clock}) => {
        setElapsed(elapsed + clock.getDelta() * 250)
        const rotOffset = Math.sin(elapsed * 0.15) * (15 * Math.PI / 180)
        pulseRef.current.rotation.y = rotOffset
    })

    return (
        <group position={mobile ? [0, 0, 4.5] : [0, 0, 4.9]} rotation={[0 * Math.PI / 180, 0 * Math.PI / 180, 0 * Math.PI / 180]} scale={[13.5,13.5,13.5]} onClick={onBoxClicked}>
            <group ref={pulseRef}>
                <animated.group rotation-y={flipTransition.rot}>
                    <primitive object={scene}/>
                </animated.group>
            </group>
        </group>

    )
}

export default NewCardBox