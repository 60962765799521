import {useState, useRef, useEffect} from "react";
import {useFrame, useThree} from "@react-three/fiber";
import {Text, useTexture} from "@react-three/drei";
import {useSpring, animated, config} from '@react-spring/three';
import {prompts} from "../prompts";

const FocusCard = ({
                       shouldEnter,
                       dismissCard,
                       promptIndex,
                       setFocusCardVisible,
                       setShowNewCardBox,
                       newCardPromptIndex,
                       mobile
                   }) => {

    const [shouldFlip, setShouldFlip] = useState(false)
    const [flipped, setFlipped] = useState(false)
    const [elapsed, setElapsed] = useState(0)
    const [isEntering, setIsEntering] = useState(false)

    const cardRef = useRef()
    const pulseRef = useRef()

    const texture = useTexture(promptIndex > -1 ? 'card' + Number(promptIndex % 6 + 1) + '.png' : newCardPromptIndex ? 'card' + Number((newCardPromptIndex - 1) % 6 + 1) + '.png' : "card1.png")

    const enterTransition = useSpring({
        to: {y: shouldEnter ?? !isEntering ? 0 : 4},
        config: config.gentle,
        onStart: () => {
            console.log('focusCard onStart')
            setIsEntering(true)
        },
        onRest: () => {
            console.log('focusCard onRest')
            setFocusCardVisible(true)
            setShowNewCardBox(false)
            setIsEntering(!isEntering)
        }
    })

    const flipTransition = useSpring({
        to: {rot: shouldFlip && flipped ? 180 * Math.PI / 180 : 0},
        config: config.gentle
    })

    useFrame(({clock}) => {
        if (flipped) {
            setElapsed(elapsed + clock.getDelta() * 250)
            const rotOffset = Math.sin(elapsed * 0.15) * (-15 * Math.PI / 180)
            pulseRef.current.rotation.y = rotOffset
        } else {
            setElapsed(0)
            pulseRef.current.rotation.y = 0
        }
    })

    // console.log('card index:', newCardPromptIndex)

    return (

        <animated.group position={mobile ? [0, 4, 6] : [0, 4, 6]} scale={mobile ? [0.75, 0.75, 0.75] : [0.9, 0.9, 0.9]} position-y={enterTransition.y} onClick={() => {
            setFlipped(!flipped)
            setShouldFlip(true)
        }} onPointerMissed={() => {
            setFlipped(false)
            dismissCard()
        }}>
            <directionalLight position={[10, 0, 15]}/>
            <group ref={pulseRef}>
                <animated.mesh ref={cardRef} rotation-y={flipTransition.rot}>
                    <boxGeometry args={[1 * 1.5, 1.5 * 1.5, 0.005]}/>
                    <meshStandardMaterial color="white" map={texture}/>
                    <mesh position={[0, 0, -0.005]} rotation={[0, 180 * Math.PI / 180, 0]}>
                        <boxGeometry args={[1 * 1.5, 1.5 * 1.5, 0.005]}/>
                        <meshStandardMaterial color="white"/>
                    </mesh>
                    <Text fontSize={0.06} position={[0, 1.05, -0.01]} scale={[-1, 1, 1]} maxWidth={1.3} anchorX="center"
                          anchorY='top' color="black" font="fonts/OpenSans-Light.ttf">
                        {prompts[promptIndex] ? prompts[promptIndex] : prompts[newCardPromptIndex - 1]}
                    </Text>

                    <Text fontSize={0.04} position={[-0.03, -1.01, -0.01]} scale={[-1, 1, 1]} maxWidth={1.3}
                          anchorX="left" anchorY='top' color="black" font="fonts/OpenSans-Light.ttf">
                        ©
                    </Text>

                    <Text fontSize={0.06} position={[-0.075, -1.0, -0.01]} scale={[-1, 1, 1]} maxWidth={1.3}
                          anchorX="left" anchorY='top' color="black" font="fonts/OpenSans-Light.ttf">
                        Dr. Deepika Chopra
                    </Text>
                </animated.mesh>
            </group>
        </animated.group>

    )
}

export default FocusCard