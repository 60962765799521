import {create} from 'zustand';

export const useAuthStore = create((set) => (
        {
            authState: false,
            setAuthState: (newAuth) => {
                console.log('Setting new auth state:', newAuth)
                set({authState: newAuth})
            },
        }
    )
)